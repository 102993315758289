import { IonContent, IonPage, } from '@ionic/react';
import React from 'react';
// import ReactDOM from 'react-dom';
// import ExploreContainer from '../components/ExploreContainer';
import { IonSlides, IonSlide } from '@ionic/react';
import './Home.css';

const slideOpts = {
      effect: 'fade',
      initialSlide: 0,
      speed: 400,
    zoom: false,
    threshold: 12,
    loop: true
    // freeMode: true,
    // freeModeSticky:true
};

//
// const slideColors = [
//     'red', 'green', 'yellow', 'purple'
// ];

let slideAnimals = [
    {
        name: 'alligator',
        img: 'alligator',
        color: '#46818e',
        sound: 'alligator',
        slideRef: React.createRef,
        slideRef2: React.createRef,
    },
    {
        name: 'beaver',
        img: 'beaver',
        color: '#46818e',
        sound: 'beaver'
    },
    {
        name: 'bear',
        img: 'beer',
        color: '#46818e',
        sound: 'bear',
    },
    {
        name: 'camel',
        img: 'camel',
        color: '#f0c332',
        sound: 'camel'
    },
    {
        name: 'cat',
        img: 'cat',
        color: '#674fa7',
        sound: 'cat'
    },
    {
        name: 'chicken',
        img: 'chicken',
        color: '#69a84f',
        sound: 'chicken'
    },
    {
        name: 'chihuahua',
        img: 'chihuahua',
        color: '#69a84f',
         sound: 'chihuahua'
    },
    {
        name: 'cow',
        img: 'cow',
        color: '#69a84f',
        sound: 'cow'
    },
    {
        name: 'duck',
        img: 'duck',
        color: '#e69136',
        sound: 'duck'
    },
    {
        name: 'dog',
        img: 'dog',
        color: '#e69136',
        sound: 'dog'
    },
    {
        name: 'dolphin',
        img: 'dolphin',
        color: '#3d85c7',
        sound: 'dolphin'
    },
    {
        name: 'elephant',
        img: 'elephant',
        color: '#a64d79',
        sound: 'elephant'
    },
    {
        name: 'fish',
        img: 'fish',
        color: '#3d85c7',
        sound: 'fish'
    },
    {
        name: 'frog',
        img: 'frog',
        color: '#f0c332',
        sound: 'frog'
    },
    {
        name: 'giraffe',
        img: 'giraffe',
        color: '#f0c332',
        sound: 'giraffe'
    },
    {
        name: 'goat',
        img: 'goat',
        color: '#69a84f',
        sound: 'goat'
    },
    {
        name: 'hamster',
        img: 'hamster',
        color: '#cc0000',
        sound: 'hamster',
    },
    {
        name: 'horse',
        img: 'horse',
        color: '#cc0000',
        sound: 'horse'
    },
    {
        name: 'koala',
        img: 'koala',
        color: '#cc0000',
        sound: 'koala'
    },
    {
        name: 'lion',
        img: 'lion',
        color: '#cc0000',
        sound: 'lion'
    },
    {
        name: 'mouse',
        img: 'mouse',
        color: '#cc0000',
        sound: 'mouse'
    },
    {
        name: 'ostrich',
        img: 'ostrich',
        color: '#f0c332',
        sound: 'ostrich'
    },
    {
        name: 'owl',
        img: 'owl',
        color: '#f0c332',
        sound: 'owl'
    },
    {
        name: 'parrot',
        img: 'parrot',
        color: '#f0c332',
        sound: 'parrot'
    },
    {
        name: 'penguin',
        img: 'penguin',
        color: '#f0c332',
        sound: 'penguin',
    },
    {
        name: 'pig',
        img: 'pig',
        color: '#f0c332',
        sound: 'pig'
    },
    {
        name: 'rabbit',
        img: 'rabbit',
        color: '#f0c332',
        sound: 'rabbit'
    },
    {
        name: 'rhino',
        img: 'rhino',
        color: '#674fa7',
        sound: 'rhino'
    },
    {
        name: 'seal',
        img: 'seal',
        color: '#3d85c7',
        sound: 'seal'
    },
    {
        name: 'sheep',
        img: 'sheep',
        color: '#69a84f',
        sound: 'sheep'
    },
    {
        name: 'tiger',
        img: 'tiger',
        color: '#674fa7',
        sound: 'tiger'
    },
    {
        name: 'turtle',
        img: 'turtle',
        color: '#a64d79',
        sound: 'turtle'
    },
    {
        name: 'whale',
        img: 'whale',
        color: '#3d85c7',
        sound: 'whale'
    },
    {
        name: 'wolf',
        img: 'wolf',
        color: '#674fa7',
        sound: 'wolf'
    },
    {
        name: 'zebra',
        img: 'zebra',
        color: '#46818e',
        sound: 'zebra'
    },
]



const Home: React.FC = () => {

    // let lastColor:any = false;

    // const random_color = (test: any) => {
    //
    //     var prevColor = lastColor;
    //     while (lastColor == prevColor) {
    //         lastColor = slideColors[Math.floor(Math.random()*slideColors.length)];
    //         console.log(lastColor, prevColor);
    //     }
    //     return lastColor;
    // }

    let highScore = 0;
    if (window.localStorage.getItem('miniGameHighScore')) {
        highScore = parseInt((window.localStorage.getItem('miniGameHighScore') as any));
    }

    for (var i = 0; i < slideAnimals.length; i++) {
         slideAnimals[i].slideRef = React.createRef;
         slideAnimals[i].slideRef2 = React.createRef;
    }

    let refScoreHolder = React.createRef;
    let refScoreCounter = React.createRef;
    let refScoreHighScore = React.createRef;
    let refScorePrizeBar = React.createRef;

    let shuffle = (arr: any) => {
        var i,
            j,
            temp;
        for (i = arr.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            temp = arr[i];
            arr[i] = arr[j];
            arr[j] = temp;
        }
        return arr;
    };

    const audio = new Audio();
    const audio2 = new Audio();
    const audioUI = new Audio();
    var AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    // var audioCtx = new AudioContext();

    let audioFirstPlayed = false;
    document.body.addEventListener('touchend', function(e){
        if (!audioFirstPlayed) {
            console.log("Komt ie!");
            audioFirstPlayed = true;
            try {
                audio.play();
            } catch (err) {

            }


            // let gainNode = audioCtx.createGain();
            // gainNode.gain.value = 0.5;
            //
            // let osc = audioCtx.createOscillator();
            // osc.frequency.value = 50;
            // osc.type = 'sine';
            // osc.detune.value = 0;
            //
            // osc.connect(gainNode);
            //
            // osc.start();
            //
            // gainNode.connect(audioCtx.destination);
        }

    });

    let SliderRefs : any = {
        top: React.createRef,
        bottom: React.createRef
    }

    let currentSlides = {
      top: {},
      bottom: {}
    };

    let slideChanged = (event:any, arr:any, pos:any) => {
        let index: number = 0;
        event.target.getActiveIndex().then(
            (value: any) => {
                index = getSlideIndex(value, arr);
                console.log("Slide: ", pos, index, arr[index]);

                if (pos === 'top' && miniGamePlaying && currentMiniGameTopSlide !== index) {
                    stopMiniGame();
                }
                if (pos === 'top') {
                    showScoreCounter();
                }

                (currentSlides as any)[pos] = arr[index];
            }
        );
    }

    let miniGamePlaying = false;
    let miniGamePaused = false;
    let currentScoreInterval :any = null;
    let currentScore = 0;
    let currentScorePrize = 0;
    let currentScorePrizeWon = 0;
    let currentMiniGameTopSlide = -1;
    let animalsFound = 0;

    let miniGameScoreCounter = () => {
        if (currentScorePrize > 0) {
            currentScorePrize -= 1;
        }
        if (currentScorePrizeWon > 0) {
            let scoreAdded = Math.ceil(currentScorePrizeWon / 25);
            currentScorePrizeWon -= scoreAdded;
            currentScore += scoreAdded;
        }

        (refScoreCounter as any).innerHTML = (currentScore > 0) ? currentScore : "";

        (refScoreHighScore as any).innerHTML = (highScore > 0) ? highScore : "";

        let barWidth = (currentScorePrize + currentScorePrizeWon) / 1000 * 100;
        (refScorePrizeBar as any).style.width = barWidth+"%";

        if ((currentScorePrize) <= 0 && !miniGamePaused && miniGamePlaying) {

            stopMiniGame();
        }

    }

    let showScoreCounter = () => {
        if (miniGamePlaying) {
            (refScoreHolder as any).style.display = 'block';
        } else {
            (refScoreHolder as any).style.display = 'none';
        }
    }

    let stopMiniGame = () => {
        audioUI.src = 'assets/ui-sounds/ting.mp3';
        audioUI.play();
        if (miniGamePlaying) {

            if (currentScore > highScore) {
                highScore = currentScore;
                window.localStorage.setItem('miniGameHighScore', (highScore as any));
            }

            clearInterval(currentScoreInterval);
            currentScoreInterval = false;
            miniGamePlaying = false;

            currentScorePrizeWon = 0;
            currentScorePrize = 0;
            miniGameScoreCounter();

            currentScore = 0;
            animalsFound = 0;
        }
    }

    let getSlideIndex = (value: any, arr:any) => {
        value = value - 1;
        if (value < 0) value = arr.length - 1;
        if (value >= arr.length) value = 0;
        return value;
    }


    let slideClicked = (event:any, arr:any, pos:any, auto:boolean = false) => {

        let otherPos = (pos === 'bottom') ? 'top' : 'bottom';
        if (!audioFirstPlayed) {
            audioFirstPlayed = true;
            // audio.play();
        }
        console.log(event);
        let index: number = 0;
        event.target.getActiveIndex().then(
            (value: any) => {
                index = getSlideIndex(value, arr);

                let thisAudio = audio;
                if (pos === 'bottom') {
                    thisAudio = audio2;
                }
                console.log(index, arr[index]);
                let thisAnimal = arr[index];

                if (thisAnimal.slideRef) {
                    // let node = ReactDOM.findDOMNode(thisAnimal.slideRef) as HTMLElement;

                    if (!arr[index].sound) {
                        arr[index].sound = 'zoowawa';
                    }

                    if (arr[index].sound) {
                        let node = document.getElementById('slide-'+pos+'-'+index) as HTMLElement;
                        let nodes = document.querySelectorAll('#slide-'+pos+'-'+index) as any;

                        console.log("All Nodes found: ",nodes);

                        if (node.classList.contains('slide-sound-playing')) return false;


                        let elms = [] as any;
                        for (var key = 0; key < nodes.length; key++) {
                            console.log("Key: ", key);
                            nodes[key].classList.remove('slide-sound-playing');
                            var thisImgs = nodes[key].getElementsByTagName("img");
                            elms.push(thisImgs[0]);
                        }



                        try {
                            thisAudio.pause();
                            thisAudio.src = 'assets/animal-sounds/' + arr[index].sound + '.mp3';
                        } catch(err) {

                        }

                        thisAudio.onloadedmetadata = function(){
                            var val = thisAudio.duration;
                            console.log(">>>" + val);

                            for (var key = 0; key < nodes.length; key++) {
                                if ((elms[key].style as any).webkitAnimationDuration) {
                                    (elms[key].style as any).webkitAnimationDuration = thisAudio.duration + "s";
                                    elms[key].style.display = "none";
                                    if (key == nodes.length - 1) {
                                        setTimeout(() => {
                                            for (var key = 0; key < nodes.length; key++) {
                                                elms[key].style.display = "inline-block";
                                                nodes[key].classList.add('slide-sound-playing');
                                            }
                                        }, 0);
                                    }
                                } else {
                                    elms[key].style.animationDuration = thisAudio.duration + "s";
                                    nodes[key].classList.add('slide-sound-playing');
                                }
                            }

                            thisAudio.pause();
                            thisAudio.play();

                            if ((currentSlides as any)[otherPos].name === arr[index].name) {
                                if (currentScoreInterval) {
                                    currentScorePrizeWon = currentScorePrize;
                                    currentScorePrize = 0;
                                    miniGamePaused = true;
                                }
                            } else if (currentScoreInterval) {

                                stopMiniGame();
                            }

                            let endFunction = (event:any) => {
                                for (var key = 0; key < nodes.length; key++) {
                                    nodes[key].classList.remove('slide-sound-playing');
                                }
                                thisAudio.removeEventListener('ended', endFunction);

                                if (!auto) {
                                    if ((currentSlides as any)[otherPos].name === (currentSlides as any)[pos].name) {
                                        if (otherPos === 'bottom') {
                                            slideClicked({target: SliderRefs.bottom}, bottomAnimals, 'bottom', true);
                                        } else {
                                            slideClicked({target: SliderRefs.top}, topAnimals, 'top', true);
                                        }
                                    }
                                } else {
                                    // spin the weel!
                                    audioUI.src = 'assets/ui-sounds/woosh.mp3';
                                    audioUI.play();
                                    // if (otherPos === 'bottom' && 1 === 2) {
                                    //     let toSlide = Math.floor(Math.random()*bottomAnimals.length);
                                    //     while (bottomAnimals[toSlide].name === (currentSlides.bottom as any).name) {
                                    //         let toSlide = Math.floor(Math.random()*bottomAnimals.length);
                                    //     }
                                    //     SliderRefs.bottom.slideTo(toSlide);
                                    // } else {

                                    let toSlide = Math.floor(Math.random()*topAnimals.length);
                                    currentMiniGameTopSlide = getSlideIndex(toSlide, topAnimals);
                                    while (topAnimals[currentMiniGameTopSlide].name === (currentSlides.top as any).name) {
                                        toSlide = Math.floor(Math.random()*topAnimals.length);
                                        currentMiniGameTopSlide = getSlideIndex(toSlide, topAnimals);
                                    }

                                    SliderRefs.top.slideTo(toSlide);

                                    // }

                                    if (!miniGamePlaying) {
                                        miniGamePlaying = true;
                                        showScoreCounter();
                                    }
                                    miniGamePaused = false;
                                    currentScorePrize = 1000;

                                    if (!currentScoreInterval) {
                                        currentScoreInterval = setInterval(miniGameScoreCounter, 10);
                                    }

                                }

                            };

                            thisAudio.addEventListener('ended', endFunction, false);

                        };




                    }

                }

            }
        );

    }




    let topAnimals = [...shuffle(slideAnimals)];
    console.log(topAnimals);

    let bottomAnimals = shuffle(slideAnimals);

    console.log(topAnimals);
    console.log(bottomAnimals);

    currentSlides.top = topAnimals[0];
    currentSlides.bottom = bottomAnimals[0];

  return (
    <IonPage>
      {/*<IonHeader>*/}
      {/*  <IonToolbar>*/}
      {/*    <IonTitle>Blank</IonTitle>*/}
      {/*  </IonToolbar>*/}
      {/*</IonHeader>*/}
      <div className="score-holder" ref={(el) => { (refScoreHolder as any) = el }} >
          <div className="score" >
              <div className="score-counter" ref={(el) => { (refScoreCounter as any) = el }} >750</div>
              <div className="highscore" ref={(el) => { (refScoreHighScore as any) = el }} ></div>
          </div>
          <div className="score-prize-bar-holder">
              <div className="score-prize-bar" ref={(el) => { (refScorePrizeBar as any) = el }}>

              </div>
          </div>

      </div>
      <IonContent no-bounce overflow-scroll="false" scrollY={false}>
          <IonSlides pager={false} ref={(el) => { SliderRefs.top = el }} options={slideOpts} className="slidesTop" onIonSlideTap={(event: any)=> slideClicked(event, topAnimals, 'top')} onIonSlideDidChange={(event: any)=> slideChanged(event, topAnimals, 'top')}>
              {topAnimals.map((animal:any, i:any) => {
                  return (
                      <IonSlide key={i} id={'slide-top-'+i} ref={(el) => { animal.slideRef = el }} style={{backgroundColor: animal.color}}>
                          <img src={'assets/animals/'+animal.img+'.png'} alt="" draggable="false" />
                      </IonSlide>
                  )
              })}
          </IonSlides>
          <IonSlides pager={false} ref={(el) => { SliderRefs.bottom = el }} options={slideOpts} className="slidesBottom" onIonSlideTap={(event: any)=> slideClicked(event, bottomAnimals, 'bottom')} onIonSlideDidChange={(event: any)=> slideChanged(event, bottomAnimals, 'bottom')}>
              {bottomAnimals.map((animal:any, i:any) => {
                  return (
                      <IonSlide key={i} id={'slide-bottom-'+i} ref={(el) => { animal.slideRef = el }} style={{backgroundColor: animal.color}} >
                          <img src={'assets/animals/'+animal.img+'.png'} alt="" draggable="false" />
                      </IonSlide>
                  )
              })}
          </IonSlides>
        </IonContent>
    </IonPage>
  );
};

export default Home;
